<template>
  <div class="animated fadeIn">
    <b-row>
    </b-row>
    <b-card> Dashboard
    </b-card>
    <b-row>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'Dashboard',
  components: {
  },
  data: function () {
    return {
     
     
      
    }
  },
  methods: {
    
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
